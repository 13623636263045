import React, { useState, useEffect } from 'react';
import '../workInProgress/LotFilter.css';
import CentralCalls from '../../centralCalls';
import { CircularProgress, Dialog, DialogActions, DialogContent, FormControl, TextField, IconButton, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import GenericInsert from '../GenericInsert';

const MaterialSelector2 = ({ onMaterialModelChange, lot_oid, room_material_row_in }) => {
    const [materialTypes, setMaterialTypes] = useState([]);
    const [selectedMaterialType, setSelectedMaterialType] = useState(null);
    const [manufacturerFilterOptions, setManufacturerFilterOptions] = useState([]); // New filter options
    const [selectedManufacturer, setSelectedManufacturer] = useState(null); // Selected value for new filter
    const [materialModels, setMaterialModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMaterialModel, setSelectedMaterialModel] = useState('');
    const [showInsertDialog, setShowInsertDialog] = useState(false);
    const [dialogComponentType, setDialogComponentType] = useState('material_type');
    const [existingRooms, setExistingRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(0);
    const [presetLoadComplete, setPresetLoadComplete] = useState(false);
    const location = useLocation();
    const useQuery = () => new URLSearchParams(location.search);
    const query = useQuery();
    const modelOidFromUrl = query.get('model_oid');
    useEffect(() => {
        const fetchMaterialTypes = async () => {
            console.log('lading types...')
            if (lot_oid) {
                console.log('found lot_oid');
                const rooms = await CentralCalls.callStoredProcedureWithParams('sp_getExistingRoomsPerLot', { 'lot_oid': lot_oid });
                setExistingRooms(rooms);
            }
            await loadMaterialTypes();
            console.log('loaded materials...')
            if (room_material_row_in.oid && room_material_row_in.oid > 0) {
                await setLoading(true);
                console.log('loading....' + room_material_row_in.material_model_oid)
                const rows = await CentralCalls.fetchTableData('material_model', { oid: room_material_row_in.material_model_oid });
                if (rows && rows.length > 0) {
                    console.log('got rows...');
                    console.log(rows[0]);
                    const { material_type_oid, material_manufacturer_oid } = rows[0];
                    const rm = await CentralCalls.fetchTableData('room_material', {oid: room_material_row_in.oid});
                    const { material_room_oid} = rm[0];
                    setSelectedRoom(material_room_oid)
                    setSelectedMaterialType(material_type_oid);
                    await loadManufacturerFilterOptions(material_type_oid);
                    setSelectedManufacturer(material_manufacturer_oid);
                    await loadMaterialModelsPreset(material_manufacturer_oid, material_type_oid);
                    setSelectedMaterialModel(room_material_row_in.material_model_oid);
                    console.log('setting final model...' + room_material_row_in.material_model_oid)
                    setLoading(false);
                    setPresetLoadComplete(true);
                }else{setLoading(false);}
                
            } else {
                setLoading(false);
            }
        }
        fetchMaterialTypes();
    }, [room_material_row_in, lot_oid]);


    // useEffect(() => {
    //     const getRoomList = async () => {
    //         if (lot_oid) {
    //             const rooms = await CentralCalls.callStoredProcedureWithParams('sp_getExistingRoomsPerLot', { 'lot_oid': lot_oid });
    //             //setLoading(false);
    //             setExistingRooms(rooms);
    //         }
    //     };
    //     getRoomList();
    // }, [lot_oid]);

    const loadMaterialTypes = async () => {
        const rows = await CentralCalls.fetchTableData('material_type', {});
        setMaterialTypes(rows);
    };

    const loadManufacturerFilterOptions = async (materialTypeOid) => {
        const rows = await CentralCalls.fetchTableData('material_model', { 'material_type_oid': materialTypeOid });
        const finalRows = await CentralCalls.fetchTableData('material_manufacturer');
        if (rows) {
            // Get a set of all `material_manufacturer_oid` values in `rows`
            const manufacturerOidsInRows = new Set(rows.map(row => row.material_manufacturer_oid));

            // Filter `finalRows` to include only rows with an `oid` that exists in `manufacturerOidsInRows`
            const filteredFinalRows = finalRows.filter(row => manufacturerOidsInRows.has(row.oid));
            console.log(filteredFinalRows);
            //setMaterialModels(filteredFinalRows);
            setManufacturerFilterOptions(filteredFinalRows);
        };
    }

    const loadMaterialModels = async (manufacturerOid) => {
        const rows = await CentralCalls.fetchTableData('material_model', { 'material_manufacturer_oid': manufacturerOid, 'material_type_oid': selectedMaterialType });
        setMaterialModels(rows);
    };
    const loadMaterialModelsPreset = async (manufacturerOid, materialTypeOid) => {
        const rows = await CentralCalls.fetchTableData('material_model', { 'material_manufacturer_oid': manufacturerOid, 'material_type_oid': materialTypeOid });
        setMaterialModels(rows);
    };
    const handleMaterialTypeChange = (oid) => {
        setSelectedMaterialType(oid);
        setSelectedMaterialModel(null);
        if (oid) {
            loadManufacturerFilterOptions(oid); // Load new filter options based on selected fixture
        }
        onMaterialModelChange({ material_model_oid: 0, material_room_oid: selectedRoom });
        setSelectedMaterialModel(0);
    };

    const handleMenufacturerFilterChange = (oid) => {
        setSelectedManufacturer(oid);
        setSelectedMaterialModel(null);
        if (oid) {
            loadMaterialModels(oid); // Load description based on new filter
        }
        onMaterialModelChange({ material_model_oid: 0, material_room_oid: selectedRoom });
        setSelectedMaterialModel(0);
    };

    const handleMaterialModelChange = (oid) => {
        setSelectedMaterialModel(oid);
        onMaterialModelChange({ material_model_oid: oid, material_room_oid: selectedRoom });
    };

    return (
        <>
            <div className="lot-filter-container">
                {loading ? (
                    <CircularProgress />
                ) : (
                    <div className="lot-filter">
                        <Autocomplete
                            options={existingRooms.sort((a, b) => a.name.localeCompare(b.name)) || []}
                            getOptionLabel={(option) => option.name || ''}
                            style={{ width: '100%' }}
                            onChange={(event, newValue) => setSelectedRoom(newValue ? newValue.oid : null)}
                            value={existingRooms.find(mt => mt.oid === selectedRoom) || null}
                            renderInput={(params) => (
                                <FormControl fullWidth required>
                                    <TextField {...params} label="Room" variant="standard" required />
                                </FormControl>
                            )}
                        />
                        <Autocomplete
                            disabled={selectedRoom === 0}
                            options={materialTypes.sort((a, b) => a.type.localeCompare(b.type)) || []}
                            getOptionLabel={(option) => option.type || ''}
                            style={{ width: '100%' }}
                            onChange={(event, newValue) => handleMaterialTypeChange(newValue ? newValue.oid : null)}
                            value={materialTypes.find(mt => mt.oid === selectedMaterialType) || null}
                            renderInput={(params) => (
                                <FormControl fullWidth required>
                                    <TextField {...params} label="Fixture" variant="standard" required />
                                </FormControl>
                            )}
                        />
                        <IconButton onClick={() => setShowInsertDialog(true)}>
                            <AddIcon />
                        </IconButton>

                        <Autocomplete
                            disabled={!selectedMaterialType}
                            options={manufacturerFilterOptions.sort((a, b) => a.name.localeCompare(b.name)) || []}
                            getOptionLabel={(option) => option.name || ''}
                            style={{ width: '100%' }}
                            onChange={(event, newValue) => handleMenufacturerFilterChange(newValue ? newValue.oid : null)}
                            value={manufacturerFilterOptions.find(nf => nf.oid === selectedManufacturer) || null}
                            renderInput={(params) => (
                                <FormControl fullWidth required>
                                    <TextField {...params} label="Manufacturer Filter" variant="standard" required />
                                </FormControl>
                            )}
                        />

                        <Autocomplete
                            disabled={!selectedManufacturer}
                            options={materialModels.sort((a, b) => a.model_number.localeCompare(b.model_number)) || []}
                            getOptionLabel={(option) => option.description || ''}
                            style={{ width: '100%' }}
                            onChange={(event, newValue) => handleMaterialModelChange(newValue ? newValue.oid : null)}
                            value={materialModels.find(mt => mt.oid === selectedMaterialModel) || null}
                            renderInput={(params) => (
                                <FormControl fullWidth required>
                                    <TextField {...params} label="Description" variant="standard" required />
                                </FormControl>
                            )}
                        />
                    </div>
                )}
            </div>

            <Dialog fullWidth maxWidth="lg" open={showInsertDialog} onClose={() => setShowInsertDialog(false)}>
                <DialogContent style={{ overflow: 'visible' }}>
                    {dialogComponentType === 'material_type' && (
                        <GenericInsert displayName="Fixture" tableName="material_type" onInsert={handleMaterialTypeChange} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowInsertDialog(false)} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default MaterialSelector2;
