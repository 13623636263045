import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import LotFilter from '../workInProgress/LotFilter';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Tooltip,
    IconButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import CentralCalls from '../../centralCalls';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
const Permit = ({ pageType }) => {
    console.log("FROM PERMIT.." + pageType)
    const [StatusDialogOpen, setStatusDialogOpen] = useState(false);
    const [AddPermitDialogOpen, setAddPermitDialogOpen] = useState(false);
    const [currCustomFunctions, setCurrCustomFunctions] = useState([]);
    const [SelectedPermitOid, setSelectedPermitOid] = useState(0);
    const [SelectedLotOid, setSelectedLotOid] = useState(0);

    const [AddPermitValid, setAddPermitValid] = useState(null);
    const [NewPermitLotOid, setNewPermitLotOid] = useState(0);
    const [lotTitle, setLotTitle] = useState('no title');
    const [refreshKeyOpen, setRefreshKeyOpen] = useState(500);
    const [refreshKeyComplete, setRefreshKeyComplete] = useState(2000);
    const [refreshKeyDelete, setRefreshKeyDelete] = useState(3000);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());
    const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const getLotTitle = async () => {
            const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', { lot_oid: SelectedLotOid });
            setLotTitle(title.title);
        }
        getLotTitle();
    }, [SelectedLotOid]);
    const handleRevertCompletePermit = async (permitRow) => {
        CentralCalls.upsertData({ oid: permitRow.oid, complete: 0, date_permited: null }, 'permit', 'update');
        setRefreshKeyComplete(refreshKeyComplete + 1);
        setRefreshKeyOpen(refreshKeyOpen + 1);
    }
    const handleOpenCompletePermit = async (slabRow) => {
        setStatusDialogOpen(true);
        setSelectedLotOid(slabRow.lot_oid);
        setSelectedPermitOid(slabRow.oid);
    }
    const handleWipLinkClick = async (phaseRow) => {
        const path = '/wip?lot_oid=' + phaseRow.lot_oid;
        navigate(path);
    }
    const checkDisplayComplete = (permitRow) => {
        if (permitRow.date_applied && permitRow.date_applied !== null) { return true; } else { return false; }
    }
    const undeletedRow = (deletedRow) => {
        CentralCalls.upsertData({ deleted: 0, oid: deletedRow.oid }, 'permit', 'update');
        setRefreshKeyOpen(refreshKeyOpen + 1);
        setRefreshKeyDelete(refreshKeyDelete + 1);
    }
    const formatDate = (date) => {
        if (!date) return '';
        return format(date, 'MM/dd/yyyy');
      };
    const customFunctions = [
        {
            display_name: 'Complete',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: handleOpenCompletePermit,
            displayCheck: checkDisplayComplete
        },
        {
            display_name: 'WIP',
            type: 'overview',
            func: handleWipLinkClick
        }
    ]
    const customDeleteFunctions = [
        {
            display_name: 'Revert',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: undeletedRow
        }
    ]
    const customFunctionsCompletion = [
        {
            display_name: 'Undo',
            type: 'revert', // type must correspond to a FontAwesome icon name
            func: handleRevertCompletePermit
        }
    ]
    //   useEffect(() => {
    //     const setPageVariables = async () => {
    //       if(pageType === 'complete'){
    //         setCurrCustomFunctions(customFunctionsCompletion);
    //       }
    //       else if(pageType === 'deleted'){
    //         setCurrCustomFunctions(customDeleteFunctions);
    //       } else {
    //         setCurrCustomFunctions(customFunctions);
    //       }
    //     }
    //     setPageVariables();
    //   }, [pageType]);
    //const [SelectedPermitOid, setSelectedPermitOid] = useState(0);
    const handleCompletePermit = async (slabRow) => {
        setStatusDialogOpen(false);
        setRefreshKeyOpen(refreshKeyOpen + 1);
        setRefreshKeyComplete(refreshKeyComplete + 1);
    };
    const handleSavePermit = async (slabRow) => {
        setSelectedLotOid(slabRow.lot_oid);
        setSelectedPermitOid(slabRow.oid);
        setRefreshKeyOpen(refreshKeyOpen + 1);
        setRefreshKeyComplete(refreshKeyComplete + 1);
    };
    const handleSaveDelete = async (slabRow) => {
        setRefreshKeyDelete(refreshKeyDelete + 1);

    };
    const handleDateChange = (item) => {
        const newStartDate = item.selection.startDate;
        const newEndDate = item.selection.endDate;
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };




    const procedureDetails = {
        procedureName: 'ui_sp_getPermitPageData',
        parameters: { 'complete': 0 },  // If there are any parameters, define them here as an object
    };
    const procedureDetailsCompletion = {
        procedureName: 'ui_sp_getPermitCompletionPageData',
        parameters: { 'lot_oid': SelectedLotOid, 'permit_oid': SelectedPermitOid },  // If there are any parameters, define them here as an object
    };
    const procedureDetailsRecent = {
        procedureName: 'ui_sp_getPermitPageData',
        parameters: { 'complete': 1, start_date : CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) },  // If there are any parameters, define them here as an object
    };
    const procedureDeleteDetails = {
        procedureName: 'ui_sp_getPermitPageData',
        parameters: { 'deleted': 1, start_date : CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) },  // If there are any parameters, define them here as an object
    };

    return (
        <div>
         
            {(pageType === 'complete' || pageType === 'deleted') && (
                    <>
                        <IconButton aria-label="save" onClick={() => setDateRangeDialogOpen(true)} size="medium">
                            <FontAwesomeIcon icon={faCalendar} />
                        </IconButton>
                        {formatDate(startDate)} - {formatDate(endDate)}
                        <br />
                    </>
                )}
            {pageType === 'open' && (<JSONTableEditor key={refreshKeyOpen}
                procedureDetails={procedureDetails}
                onSave={handleSavePermit}
                allowDelete={true}
                sortOrder={{
                    'municipality': 'asc',
                    'builder': 'asc',
                    'neighborhood': 'asc',
                    'lot': 'asc'
                }}
                customFunctions={customFunctions}
            />)}

            {pageType === 'complete' && (<>
                <JSONTableEditor key={refreshKeyComplete}
                    procedureDetails={procedureDetailsRecent}
                    customFunctions={customFunctionsCompletion}
                    onSave={handleSavePermit}
                    sortOrder={{
                        'municipality': 'asc',
                        'builder': 'asc',
                        'neighborhood': 'asc',
                        'lot': 'asc'
                    }}
                /></>)}
            {pageType === 'deleted' && (<>

                <JSONTableEditor key={refreshKeyDelete}
                    procedureDetails={procedureDeleteDetails}
                    onSave={handleSaveDelete}
                    hideEdit={true}
                    sortOrder={{
                        'municipality': 'asc',
                        'builder': 'asc',
                        'neighborhood': 'asc',
                        'lot': 'asc'
                    }}
                    customFunctions={customDeleteFunctions} />
            </>)}
            <Dialog fullWidth={true} maxWidth='lg' open={StatusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
                <DialogTitle><h1>Complete Permit</h1><h4>{lotTitle}</h4></DialogTitle>
                <DialogContent >
                    <DynamicForm
                        procedureDetails={procedureDetailsCompletion}
                        onSave={handleCompletePermit}
                        saveName='Complete'

                        crudType='update' />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setStatusDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dateRangeDialogOpen} onClose={() => setDateRangeDialogOpen(false)}>
                <DialogContent style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '400px',
                    marginLeft: '-250px'// Adjust height based on your needs
                }} >
                    <DateRangePicker
                        onChange={handleDateChange}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        direction="horizontal"
                        locale={enUS}
                        ranges={[{
                            startDate: startDate,
                            endDate: endDate,
                            key: 'selection',
                        }]}
                        inputRanges={[]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDateRangeDialogOpen(false)}>Ok</Button>
                </DialogActions>
            </Dialog>


        </div>
    );
};

export default Permit;
