import React from 'react';
import './DisplayTable.css'; // Import CSS file for styling

const DisplayTable = ({ data }) => {
    console.log(data);
    if (!data || data.length === 0) {
        return <div>No data available</div>;
      }
  // Extract the columns from the first object in the data array
  const columns = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {columns.map((column) => (
                <td key={column}>{item[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DisplayTable;
