import React, { useState, useEffect } from 'react';
import CentralCalls from '../../centralCalls';
import DynamicForm from '../DynamicForm';
const ChangePurchaseStatus = ({handlePostUpdate, lot_oid,currentOrderStatus, currentOrderStatusDisplay, newOrderStatus, orderStatusDisplay}) => {
    const[lotTitle, setLotTitle] = useState('');
    useEffect(() => {
        const getLotTitle = async () => {
            const title = await CentralCalls.callStoredProcedureWithParams('sp_getLotTitle', { lot_oid: lot_oid });
            setLotTitle(title.title);
        }
        getLotTitle();
    }, [lot_oid]);
    const handleUpdateOrderStatusClose = async (orderStatusLotRow) => {
       
        handlePostUpdate(orderStatusLotRow);
    }
    const procedureDetailsUndergroundTickets = {
        procedureName: 'ui_sp_setOrderStatus',
        parameters: {lot_oid: lot_oid, current_order_status:currentOrderStatus, current_order_status_display:currentOrderStatusDisplay, order_status:newOrderStatus, order_status_display:orderStatusDisplay }
    }
    const procedureDetailsPulled = {
        procedureName: 'ui_sp_setOrderPulledStatus',
        parameters: {lot_oid: lot_oid, current_order_status:currentOrderStatus, current_order_status_display:currentOrderStatusDisplay, order_status:newOrderStatus, order_status_display:orderStatusDisplay }
    }
    
  return (
    <div>
        <h4>{lotTitle}</h4>
       <h1>Update Order Status</h1>
        <DynamicForm
         procedureDetails={currentOrderStatus === 'open' ? procedureDetailsUndergroundTickets : procedureDetailsPulled}
         onSave={handleUpdateOrderStatusClose}
         saveName='Update'
         crudType='update' ></DynamicForm>
    </div>
  );
};

export default ChangePurchaseStatus;
