import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { faPlus, faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import './WipTabs.css';
import { Auth } from 'aws-amplify';
import DisplayTable from '../displayTable/DisplayTable';
const WipPurchaseView = ({ selectedLotOid }) => {
    const [lotRow, setLotRow] = useState([]);
    const [tableData,setTableData] = useState([]);
    const procedureDetails = useMemo(() => ({
        procedureName: 'ui_sp_wipLotPurchasing',
        parameters: { 'lot_oid': selectedLotOid },
    }), [selectedLotOid]);
    

    useEffect(() => {
        async function setUserInfo() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                //setUserName(user.attributes.email);
            } catch (error) {
                console.error('Error fetching user info', error);
            }
        }
        setUserInfo();
    }, []);

    useEffect(() => {
        async function setUserInfo() {
            try {
                if(selectedLotOid){
                    console.log("running lots")
                    var lotRow = await CentralCalls.fetchTableData('lot', {oid:selectedLotOid});
                    setLotRow(lotRow[0]);
                    setTableData([{Status:'Ordered', Date: lotRow[0].order_date, "PO Number": lotRow[0].order_po_number, Comments: lotRow[0].order_comments},
                        {Status:'Pulled', Date: lotRow[0].order_pulled_date, "PO Number": lotRow[0].order_po_number, Comments: lotRow[0].order_pulled_comments}
                    ])
                }
            } catch (error) {
                console.error('Error fetching user info', error);
            }
        }
        setUserInfo();
    }, [selectedLotOid]);
    const getStatus = () => {
        if(!selectedLotOid || selectedLotOid === 0){return ''}
        if (lotRow.order_status === 'complete') {
            return 'Pulled';
        } else if (lotRow.order_status === 'ordered') {
            return 'Ordered';
        } else {
            return 'N/A';
        }
    };
    
    const onLotUpdate = () => {}
    return (
        <>
            <h2>Purchasing ({getStatus()})</h2>
            {selectedLotOid && lotRow ? (
               <div style={{paddingLeft:'25px'}}>                    
                    {/* <DynamicForm procedureDetails={procedureDetails} displayOnly={true} onSave={onLotUpdate} saveName={'Update'} crudType={'update'} ></DynamicForm> */}
                    <DisplayTable data={tableData} ></DisplayTable>
               </div>
            ) : (
                <p>Select a lot</p>
            )}
        </>
    );
}

export default WipPurchaseView;
