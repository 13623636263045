
import React, { useState, useMemo, useEffect } from 'react';
import { IconButton, MenuItem, Select, Tooltip, Dialog, DialogTitle, DialogContent, Button, DialogActions, FormControl, TextField } from '@mui/material';
import { faPlus, faRefresh, faPrint, faTrashCan, faFaucet, faRestroom, faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import JSONTableEditor from '../JSONTableEditor';
import MaterialSelector2 from '../material/MaterialSelector';

const WipMaterialSection = ({ selectedLotOid }) => {
  const [materialTotal, setMaterialTotal] = useState(null);
  const [refreshMaterialSelectKey, setRefreshMaterialSelectKey] = useState(5000);
  const [materialModelOid, setMaterialModelOid] = useState(0);
  const [updateRoomMaterialRow, setUpdateRoomMaterialRow] = useState({});
  const [updateRoomMaterialOid, setUpdateRoomMaterialOid] = useState(0);
  const [addMaterialRoomDialogOpen, setAddMaterialRoomDialogOpen] = useState(false);
  const [selectedMaterialAddRoom, setSelectedMaterialAddRoom] = useState({ oid: 0, name: 'Select' });
  const [triggerMaterialTotalRefresh, setTriggerMaterialTotalRefresh] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const [roomsPerLot, setRoomsPerLot] = useState([]);
  const [addRoomToLotOid, setAddRoomToLotOid] = useState(0);
  const [openEditMaterial, setOpenEditMaterial] = useState(false);
  const [quantity, setQuantity] = useState(0); // Initial value set to 0
  const [cost, setCost] = useState(0.0);

  const handleOpenEditMaterial = async (materialRow) => {
    setOpenEditMaterial(true);
    setUpdateRoomMaterialOid(materialRow.oid);
    setUpdateRoomMaterialRow(materialRow);
    setCost(materialRow.cost);
    setQuantity(materialRow.quantity);
  }
  const addNewMaterialModel = async () => {
    await CentralCalls.upsertData({ 'lot_oid': selectedLotOid, 'material_room_oid': updateRoomMaterialRow.material_room_oid, 'material_model_oid': updateRoomMaterialRow.material_model_oid, quantity: quantity, cost:cost }, 'room_material', 'insert');
    resetUpdateMaterial();
    // await CentralCalls.upsertData({ 'lot_room_oid': selectedMaterialAddRoom, 'material_model_oid': newMaterialModelOid }, 'room_material', 'insert');
    // setOpenEditMaterial(false);
    // setUpdateRoomMaterialOid(0);
    // setCost(0.0);
    // setQuantity(0);
    // setRefreshMaterialSelectKey(refreshMaterialSelectKey + 1);
  }
  const updateMaterialModel = async () => {
    await CentralCalls.upsertData({ oid: updateRoomMaterialOid, 'lot_oid': selectedLotOid, 'material_room_oid': updateRoomMaterialRow.material_room_oid, 'material_model_oid': updateRoomMaterialRow.material_model_oid, quantity: quantity, cost:cost }, 'room_material', 'update');
    resetUpdateMaterial();
    // await CentralCalls.upsertData({ 'lot_room_oid': selectedMaterialAddRoom, 'material_model_oid': newMaterialModelOid }, 'room_material', 'insert');
  }
  const resetUpdateMaterial = async() => {
    setOpenEditMaterial(false);
    setUpdateRoomMaterialRow({});
    setUpdateRoomMaterialOid(0);
    setCost(0.0);
    setQuantity(0);
    setRefreshMaterialSelectKey(refreshMaterialSelectKey + 1);
  }
  const customFunctionsEditMaterials = [
    {
      display_name: 'Complete',
      type: 'edit', // type must correspond to a FontAwesome icon name
      func: handleOpenEditMaterial
    },
  ]
  const MaterialTableProcedureDetails = useMemo(() => ({
    procedureName: 'ui_sp_materialsByLotPage',
    parameters: { 'lot_oid': selectedLotOid },
  }), [selectedLotOid])

  useEffect(() => {
    const getMaterialTotal = async () => {
      if (selectedLotOid) {
        var tot = await CentralCalls.callStoredProcedureWithParams('sp_getMaterialCostTotal', { 'lot_oid': selectedLotOid });
        setMaterialTotal(tot[0].total);
      }
    }
    getMaterialTotal();
  }, [triggerMaterialTotalRefresh, updateRoomMaterialRow, selectedLotOid]);

  const sendToPrint = async () => {
    //const urlParams = 'qry=' + qry + '&employee_oids=' + selectedPlumbers.map(item => item.oid) + '&start_date=' + CentralCalls.sqlFormatdate(startDate) + '&end_date=' + CentralCalls.sqlFormatdate(endDate);
    const path = '/printMaterials?lot_oid=' + selectedLotOid;
    window.open(path, "_blank");
  }
  // const handleMaterialModelSelected = async (modelRow) => {
  //   await setSelectedMaterialAddRoom(modelRow.room_oid);
  //   setNewMaterialModelOid(modelRow.model_oid);
  // }
  const onMaterialModelChange = async (materialRow) => {
    setUpdateRoomMaterialRow(materialRow);
    //setMaterialModelOid(materialRow.)
  }
  const addNewRoomToLot = async () => {
    if (addRoomToLotOid && addRoomToLotOid > 0) {
      await CentralCalls.upsertData({ lot_oid: selectedLotOid, material_room_oid: addRoomToLotOid }, 'lot_room', 'insert');
      setAddRoomToLotOid(0);
      setAddMaterialRoomDialogOpen(false);
      setRefreshMaterialSelectKey(refreshMaterialSelectKey + 1);
    }
  }
  const getRoomList = async () => {
    var rooms = await CentralCalls.callStoredProcedureWithParams('sp_getAvailableRoomsPerLot', { 'lot_oid': selectedLotOid });
    setRoomList(rooms);
  }

  useEffect(() => {
    getRoomList();
  }, [selectedLotOid]);

  const handleSaveMaterialEditPostRun = async () => {
    setTriggerMaterialTotalRefresh(prev => !prev);
    getRoomList();
  }

  return (
    <>
      <h2>
        <IconButton
          color="primary"
          aria-label="save"
          onClick={() => { sendToPrint() }}
          size="medium">
          <FontAwesomeIcon icon={faPrint} />
        </IconButton>
        Materials
        ({CentralCalls.formatTotal(materialTotal)})
        <Tooltip title="Add Room">
          <IconButton color="primary" aria-label="save" onClick={() => setAddMaterialRoomDialogOpen(true)} size="medium">
            <FontAwesomeIcon icon={faHouse} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Material">
          <IconButton color="primary" aria-label="save" onClick={() => { setOpenEditMaterial(true); }} size="medium">
            <FontAwesomeIcon icon={faFaucet} />
          </IconButton>
        </Tooltip>
      </h2>
      <div style={{ display: 'flex' }}>

      </div>

      <JSONTableEditor
        procedureDetails={MaterialTableProcedureDetails}
        onSave={handleSaveMaterialEditPostRun}
        key={selectedMaterialAddRoom}
        hideEdit={true}
        allowDelete={true}
        customFunctions={customFunctionsEditMaterials}
        sortOrder={{
          'room': 'asc',
          'material_type': 'asc',
          'material_manufacturer': 'asc',
          'material_desc': 'asc',
          'material_model': 'asc',
          'quantity': 'asc'
        }}   >
      </JSONTableEditor>

      <Dialog fullWidth={true} maxWidth='sm' open={addMaterialRoomDialogOpen} onClose={() => setAddMaterialRoomDialogOpen(false)}>
        <DialogContent >
          <>
            <h2>Add Room</h2>
            <Select style={{ width: '300px' }}
              value={addRoomToLotOid}
              onChange={(event) => { setAddRoomToLotOid(event.target.value) }} >
              <MenuItem key={0} value={0}>
                Select Room
              </MenuItem>
              {roomList &&
                roomList.slice().sort((a, b) => a.name.localeCompare(b.name))
                  .map((option) => (
                    <MenuItem key={option.oid} value={option.oid}>
                      {option.name}
                    </MenuItem>
                  ))}
            </Select>

            <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={addRoomToLotOid === 0} aria-label="save" onClick={() => addNewRoomToLot()} size="medium">
              Insert
            </Button>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddMaterialRoomDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog fullWidth={true} maxWidth='med' open={openEditMaterial} onClose={() => { resetUpdateMaterial(); }}>
        <DialogContent >
          <>
            {(updateRoomMaterialOid === 0 || !updateRoomMaterialOid) ? (
              <h4>New Material</h4>) : (
              <h4>Update Material</h4>)}
            <MaterialSelector2 onMaterialModelChange={onMaterialModelChange} lot_oid={selectedLotOid} room_material_row_in={updateRoomMaterialRow} />
            <div style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
              <FormControl fullWidth>
                <TextField
                  label="Quantity"
                  type="number"
                  variant="standard"
                  value={quantity} // Bind the state variable
                  onChange={(e) => setQuantity(e.target.value)} // Update state on change
                  required
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label="Cost"
                  type="number"
                  inputProps={{ step: "1" }} // Step for currency input
                  variant="standard"
                  value={cost} // Bind the state variable
                  onChange={(e) => setCost(e.target.value)} // Update state on change
                  
                />
              </FormControl>
            </div>
            {(updateRoomMaterialOid === 0 || !updateRoomMaterialOid) ? (
              <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={!updateRoomMaterialRow.material_model_oid || updateRoomMaterialRow.material_model_oid === 0 || quantity <= 0} aria-label="save" onClick={() => addNewMaterialModel()} size="medium">
                Insert
              </Button>) : (
              <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={(!updateRoomMaterialRow.material_model_oid || updateRoomMaterialRow.material_model_oid === 0) || (updateRoomMaterialOid === 0 || !updateRoomMaterialOid) || quantity <= 0} aria-label="save" onClick={() => updateMaterialModel()} size="medium">
                Update
              </Button>)}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => resetUpdateMaterial()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default WipMaterialSection;