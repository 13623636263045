import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../workInProgress/LotFilter';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CentralCalls from '../../centralCalls';
import ButtonMenu from '../ButtonMenu';
import Permit from './Permit';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));
const BasePermit = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const menuItems = [{ order: 1, name_id: 'open', display_name: 'Open' }, { order: 2, name_id: 'complete', display_name: 'Complete' }, { order: 3, name_id: 'deleted', display_name: 'Deleted' }]
   // const [AddPODialogOpen, setAddPODialogOpen] = useState(false);
    const [selectedMenuItemNameId, setSelectedMenuItemNameId] = useState('open');
    const [selectedMenuItemName, setSelectedMenuItemName] = useState('Open');
    const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0]);
    const [AddPermitDialogOpen, setAddPermitDialogOpen] = useState(false);
    const [AddPermitValid, setAddPermitValid] = useState(null);
    const [NewPermitLotOid, setNewPermitLotOid] = useState(0);
    const [refreshKey, setRefreshKey] = useState(500);

    const confirmLotPermitAddOption = async (lot) => {
        const result = await CentralCalls.fetchTableData('permit', { lot_oid: lot });
        if (!result || result.length === 0) {
            //CentralCalls.upsertData({ deleted: 0, oid: deletedRow.oid }, 'permit', 'update');
            setAddPermitValid(true);
            setNewPermitLotOid(lot);
        } else {
            setAddPermitValid(false);
        }
    }
    const insertNewPermit = async () => {
        await CentralCalls.upsertData({ lot_oid: NewPermitLotOid }, 'permit', 'insert');
        setAddPermitDialogOpen(false);
        setRefreshKey(refreshKey + 1);
    }
    const menuItemSelectected = (menuItem) => {
        setSelectedMenuItemNameId(menuItem.name_id);
        setSelectedMenuItemName(menuItem.display_name);
        setSelectedMenuItem(menuItem);

    }

    console.log(selectedMenuItemNameId)
    return (
        <>
            <h1>Permits
                <Tooltip title="Add Permit">
                    <IconButton color="primary" aria-label="save" onClick={() => { setAddPermitDialogOpen(true) }} size="medium">
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip>
            </h1>
            <ButtonMenu onMenuSelect={menuItemSelectected} menuItems={menuItems} defaultMenuItem={menuItems[0]} ></ButtonMenu>
            <Permit key={refreshKey} pageType={selectedMenuItemNameId}></Permit>

            <Dialog fullWidth={true} maxWidth='lg' open={AddPermitDialogOpen} onClose={() => setAddPermitDialogOpen(false)}>
                <DialogTitle><h1>Add Permit</h1></DialogTitle>
                <DialogContent >
                    <LotFilter onLotChange={confirmLotPermitAddOption}></LotFilter>
                    <Button style={{ margin: '15px' }} variant="contained" color="primary" type="submit" disabled={AddPermitValid === false || AddPermitValid === null || NewPermitLotOid === null || NewPermitLotOid === 0} onClick={() => insertNewPermit()}  >Insert</Button>
                    {AddPermitValid === false && (<><br /><label style={{ color: 'red' }}>Existing Permit For This Lot</label></>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddPermitDialogOpen(false)} >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default BasePermit;